
<template>
  <!-- 桌台 -->
  <div>
    <el-button type="danger">
      <el-icon><Setting /></el-icon
    ></el-button>

    <div v-for="item in ManufactureInfo" :key="item.Id" class="notes_content">
      <el-card shadow="always" class="box-card">
        <div class="clearfix">
          <span class="title">{{ item.Name }}</span>

          <el-button
            @click="OverManufactureAll(item)"
            style="float: right"
            type="danger"
          >
            全出
          </el-button>
        </div>

        <div
          class="notes_items"
          v-for="(noteItem, index) in item.Items"
          :key="index"
        >
          <div class="notes_name">
            桌台/{{ noteItem.TableName }}
            <!-- {{ noteItem.ProductName }} -->
          </div>
          <div class="notes_count">
            共{{ noteItem.Count }} / 已出 {{ noteItem.MadeCount }}
          </div>
          <div class="notes_table"></div>
          <div class="notes_manage">
            <el-button
              @click="OverManufactureItem(noteItem)"
              type="warning"
              plain
            >
              出1 </el-button
            ><el-button
              @click="OverManufactureItemAll(noteItem)"
              type="danger"
              plain
            >
              全出
            </el-button>
          </div>
        </div>
      </el-card>

      <!-- <div class="notes_title">
        <div class="notes_name">{{ item.Name }}</div>
        <div class="notes_count">
          共{{ item.Count }} / 已出 {{ item.OverCount }}
        </div>
        <div class="notes_table">{{ item.TableName }}</div>
        <div class="notes_manage">
          <el-button type="warning"> 出1 </el-button
          ><el-button @click="OverManufactureAll(item)" type="danger">
            全出
          </el-button>
        </div>
      </div>
      <div
        class="notes_items"
        v-for="(noteItem, index) in item.Items"
        :key="index"
      >
        <div class="notes_name">{{ noteItem.ProductName }}</div>
        <div class="notes_count">
          共{{ noteItem.Count }} / 已出 {{ noteItem.MadeCount }}
        </div>
        <div class="notes_table">{{ noteItem.TableName }}</div>
        <div class="notes_manage">
          <el-button
            @click="OverManufactureItem(noteItem)"
            type="warning"
            plain
          >
            出1 </el-button
          ><el-button
            @click="OverManufactureItemAll(noteItem)"
            type="danger"
            plain
          >
            全出
          </el-button>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus"
import { Plus, Setting } from "@element-plus/icons-vue"
import axios from "../../../commons/AxiosMethod.js"
import "../../../assets/css/commons.css"

export default {
  name: "header",
  data () {
    return {
      ManufactureInfo: [], // 通知信息

      GetManufactureInfoInterval: null // 定时器
    }
  },
  components: {
    Setting
  },
  props: {
    Id: ""
  },
  mounted () {
    this.GetManufactureInfo()
    // this.timer = setInterval(() => {
    //   this.GetManufactureInfo();
    // }, 5000);
  },
  methods: {
    // 按照行全出
    OverManufactureItemAll (item) {
      axios.apiMethod(
        "/admins/OrderManages/OverManufactureItem",
        "get",
        {
          noticeId: item.Id,
          count: 100000
        },
        (result) => {
          this.GetManufactureInfo()
        }
      )
    },
    // 按照行出1
    OverManufactureItem (item) {
      axios.apiMethod(
        "/admins/OrderManages/OverManufactureItem",
        "get",
        {
          noticeId: item.Id,
          count: 1
        },
        (result) => {
          this.GetManufactureInfo()
        }
      )
    },
    // 按商品通知全部完成
    OverManufactureAll (item) {
      axios.apiMethod(
        "/admins/OrderManages/OverManufactureAll",
        "get",
        {
          productId: item.ProductId
        },
        (result) => {
          this.GetManufactureInfo()
        }
      )
    },
    GetManufactureInfo (item) {
      axios.apiMethod(
        "/admins/OrderManages/GetManufactureInfo",
        "get",
        {},
        (result) => {
          this.ManufactureInfo = result.Data
        }
      )
    }
  },
  beforeDestroy () {
    if (this.GetManufactureInfoInterval != null) {
      clearInterval(this.SyncImageNo)
    }
  }
}
</script>
<style scoped lang="less">
.notes_content {
  margin: 20px 15px;

  border-radius: 8px;

  .notes_title {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px #666 solid;
    padding: 0px 10px;
    font-size: 15pt;
    display: flex;
    background-color: rgb(248, 244, 244);
    .notes_name {
      width: 330px;
    }
    .notes_count {
      width: 200px;
    }
    .notes_table {
      width: 200px;
    }
    .notes_manage {
      width: 150px;
    }
  }
  .notes_items {
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;

    display: flex;
    .notes_name {
      width: 330px;
    }
    .notes_count {
      width: 200px;
    }
    .notes_table {
      width: 200px;
    }
    .notes_manage {
      width: 150px;
    }
  }
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.title {
  font-weight: 900 !important;
}

.box-card {
  width: 100%;
  border-bottom: 1px solid #ebeef5 !important;
}
</style>
